import formatHelper from '@/helpers/formatHelper';

const state = {};

const getters = {
    formatarMoeda: () => (valor) => formatHelper.formatarMoeda(valor)
};

export default {
    namespaced: true,
    state,
    getters
};
