export default {
    formatarMoeda(valor) {
        console.log('valor:',valor)
        if (!valor) return 'R$ 0,00';

        let numero = parseFloat(valor.toString().replace(/\D/g, '')) / 100;

        return numero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
};
