export const state = {
    data: {}, // Armazena as solicitações de compra
    compra: {},
    status: [],
    tipos: [],
    produtos_servicos: [],
    parecerCoordenador: {
        aprovado: true
    },
    documentos: [],
    filtroSetores: [],
    filtroCongregacoes: [],
    filtroStatuses: [],
    filtroTipos: [],
    itensSugeridos: [],
    itemSugerido: {
        id: null,
        produto_servico: '',
        quantidade: 1,
        motivo_sugestao: '',
    },
    filtro: {
        codigo_compra: null,
        setor: [],
        status_id: [],
        tipo_id: [],
        congregacao_id: []
    }
};