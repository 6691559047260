import GraphQLService from "./GraphQLService";
import { jsonToGraphQLMutation } from "../utils/JsonToGraphql.js";

const graphqlService = new GraphQLService();

const CompraService = {
    async getItensSugeridos(compra_id, headers = {}) {
        const query = `
        query {
            compraItensSugeridos (compra_id: ${compra_id}) {
                id
                compra {
                    id
                }
                produto_servico
                produtos_servicos
                {
                    titulo
                }
                quantidade
                motivo_sugestao
                preco
                marca
                fornecedor
                prazo_entrega
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getTipos(headers = {}) {
        const query = `
        query {
            tipos {
                created_at
                nome
                id
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },

    async getProdutosServicos(palavras_chaves, compras_tipo_id, headers = {}) {
        const query = `
        query {
            produtos_servicos (palavras_chaves: "${palavras_chaves}", compras_tipo_id: "${compras_tipo_id}") {
                id
                titulo
                compras_tipo_id
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },

    async getStatusByUserAndCompra(compra_id, headers = {}) {
        const query = `
        query {
            statusByUserAndCompra(compra_id: ${compra_id}) {
                created_at
                nome
                negado
                concluido
                id
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getStatus(headers = {}) {
        const query = `
        query {
            status {
                created_at
                nome
                negado
                concluido
                id
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async getCompra(id, headers = {}) {
        const query = `
        query {
            compra(id: ${id}) {
                id,
                codigo_compra
                tipo {
                    id
                    nome
                }
                user {
                    id
                }
                congregacao {
                    id
                    nome
                    setor
                }
                status {
                    id
                    nome
                }
                justificativa
                farei_campanha
                meta_campanha
                documentos {
                    id
                }
                items {
                    id
                    imagem
                    produto_servico
                    produtos_servicos {
                        titulo
                    }
                    quantidade
                }
                historico {
                    id,
                    showHistoryDoc,
                    documentos {
                        documento,
                        id,
                        nome
                    }
                    novo_status {
                        id
                        nome
                        negado
                        concluido
                    }
                    mensagem
                    created_at
                    user {
                        id
                        name
                    }
                }
                comprovante_autorizacao
                created_at
                updated_at
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
        /*
        
        */
    },

    async getCompras(page = 1, filters = {}, headers = {}) {
        console.log('getCompras', filters, jsonToGraphQLMutation(filters))
        const query = `
        query {
            compras (first: 50, page: ${page}, filters: {${jsonToGraphQLMutation(filters)}}) {
                data {
                  id,
                  codigo_compra
                  congregacao {
                    nome
                    setor
                  }
                  items {
                    imagem
                    produto_servico
                    produtos_servicos {
                        titulo
                    }
                    quantidade
                  }
                  itens_sugeridos {
                    imagem
                    produto_servico,
                    quantidade
                  }
                  status {
                    id
                    nome
                    concluido
                    negado
                  }
                  created_at
                },
                paginatorInfo {
                    total
                    currentPage
                    hasMorePages
                }
            }
        }
    `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async deleteItemSugerido(item_id, headers = {}) {
        const query = `
          mutation {
            deleteItemSugerido(
              id: ${item_id}
            ) {
                id
            }
          }
        `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async atualizarOrcamento(data = {}, headers = {}) {
        const query = `
          mutation {
            atualizarOrcamento(
              ${jsonToGraphQLMutation(data)}
            )
          }
        `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async updateItem(data = {}, headers = {}) {
        const query = `
          mutation {
            updateItem(
              ${jsonToGraphQLMutation(data)}
            ) {
                id
            }
          }
        `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    // 
    async editarItensSugeridosCompra(data = {}, headers = {}) {
        const query = `
          mutation {
            atualizarItensSugeridosCompra(
              ${jsonToGraphQLMutation(data)}
            ) {
                id
            }
          }
        `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async adicionarItensSugeridosCompra(data = {}, headers = {}) {
        const query = `
          mutation {
            adicionarItensSugeridosCompra(
              ${jsonToGraphQLMutation(data)}
            ) {
                id
            }
          }
        `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async atualizarTipo(data = {}, headers = {}) {
        const query = `
          mutation {
            atualizarTipo(
              ${jsonToGraphQLMutation(data)}
            )
          }
        `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async atualizarStatus(data = {}, headers = {}) {
        const query = `
          mutation {
            atualizarStatus(
              ${jsonToGraphQLMutation(data)}
            )
          }
        `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async criarCompra(compraData, headers = {}) {
        const query = `
          mutation {
            criarCompra(compra: {
              ${jsonToGraphQLMutation(compraData)}
            }) {
              id
            }
          }
        `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    async parecerCoordenador(parecer, headers = {}) {
        const query = `
          mutation {
            parecerCoordenador(parecer: {
              ${jsonToGraphQLMutation(parecer)}
            })
          }
        `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response;
        } catch (error) {
            throw error;
        }
    },
    async cancelarCompra(compraId, headers = {}) {
        const query = `
          mutation {
            cancelarCompra(compraId: ${compraId})
          }
        `;

        try {
            const response = await graphqlService.request(query, {}, headers);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default CompraService;